<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">2024年职工心理健康测评</h1>
      <div class="content1">亲爱的同事：<br /></div>

      <div class="content">
        您好！本次测评问卷是为了全面了解职工心理健康和工作生活状况，以便为职工提供必要的EAP帮助和针对性的服务。答题约需占用您
        <jiacu class="jiacu">10-15</jiacu
        >分钟的时间，请您认真阅读每一道题，题目<span class="jiacu"
          >没有对错之分</span
        >
        ，请根据您的<span class="jiacu">实际情况</span> 和<span class="jiacu"
          >真实感受</span
        >
        作答。
      </div>
      <br />
      <div class="content">
        您的回答对于测评得出真实确切的结果至关重要，
        <span class="jiacu">不认真或不真实回答的问卷将视为无效</span>
        ，感谢您的配合！
      </div>

      <button class="start-button" @click="startAssessment">开始测评</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  methods: {
    startAssessment() {
      const userId = localStorage.getItem("userId");
      if (!userId || userId == "undefined") {
        Toast("请先登录");
        setTimeout(() => {
          this.$router.push("/cp/yingda/login");
        }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/yingda/answer");
    },
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
}

.intro-container {
  max-width: 720px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1.5em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
}
.jiacu {
  /* 加粗 */
  font-weight: bold;
}
</style>
